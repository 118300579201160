import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';

const MainHeading = () => {
    const [currentWord, setCurrentWord] = useState('Ace');
    const words = ['Ace', 'Thrive'];

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentWord(prevWord => {
                const wordIndex = (words.indexOf(prevWord) + 1) % words.length;
                return words[wordIndex];
            });
        }, 1000); // Change every second
        return () => clearInterval(intervalId);
    }, []);

    return (
        <Box sx={{
            height: '70vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row'
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '50%',
                paddingRight: '1em',
                transition: 'opacity 0.5s ease-in-out'
            }}>
                <Typography variant="h2" component="span" sx={{
                    fontWeight: 'bold',
                    fontSize: { xs: '2.5rem', md: '8rem' },
                    display: 'inline-block',
                    marginRight: '8px', // Adjust spacing between words
                    color: currentWord === 'Ace' ? '#757575' : '#757575' // Conditional color based on the current word
                }}>
                    {currentWord}
                </Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '50%',
                paddingLeft: '1em'
            }}>
                <Typography variant="h2" component="span" sx={{
                    fontWeight: 'bold',
                    fontSize: { xs: '2.5rem', md: '8rem' },
                    display: 'inline-block',
                    color: '#bdbdbd' // Static color for "Big Tech"
                }}>
                    Big Tech
                </Typography>
            </Box>
        </Box>
    );
};

export default MainHeading;