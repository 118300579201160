import React, { useEffect } from 'react';
import { Container } from '@mui/material';

const TermsOfUse = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when the component mounts
      }, []);

    return (
        <Container maxWidth="md" sx={{ marginTop: 4, marginBottom: 4 }}> 
            <div className="WordSection1">
                <p className="MsoNormal" style={{ textAlign: 'center', lineHeight: 'normal' }}>
                    <b><span style={{ fontSize: '14pt', fontFamily: '"Century", serif', color: 'black' }}>
                    EASTBADGE TERMS OF USE
                    </span></b>
                </p>

                <p className="MsoNormal" style={{ lineHeight: 'normal' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>&nbsp;</span>
                </p>

                <p className="MsoNormal" style={{ lineHeight: 'normal' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                    Posted date:&nbsp;
                    </span>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>October 1, 2024 </span>
                </p>

                <p className="MsoNormal" style={{ lineHeight: 'normal' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                    Effective date:&nbsp;
                    </span>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>October 1, 2024 </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '10pt', lineHeight: 'normal' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                    This Terms of Use (“<b>Agreement</b>”) constitutes a legally binding contract between
                    EastBadge Inc with a mailing address of EASTBADGE, INC., 166 Geary Str STE 1500 #1458, San Francisco, CA 94108 (“<b>EASTBADGE</b>”, “<b>we</b>,” “<b>us</b>,” “<b>our</b>”) and you, as a User (“<b>User</b>”, “<b>Users</b>”, “<b>you</b>” or “<b>your</b>”) with respect to the EASTBADGE Services located at&nbsp; 
                    </span>
                    <a href="https://eastbadge.com/" target="_blank" style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: '#467886' }}>https://eastbadge.com/</a>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>.</span>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                    &nbsp;If you become a licensed user of any of EASTBADGE's websites, software, apps, and other services (collectively, our “<b>Services</b>”), the terms of your relationship with EASTBADGE will be governed according to this Terms of Use.
                    </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '10pt', lineHeight: 'normal' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                    It is important that you carefully read and understand the terms and conditions of this Agreement. By accessing and using our Services, you are providing your consent to abide by this Agreement. If you do not agree to these terms and conditions of the Agreement, you are not authorized to use the Services, and we ask that you cease any use of our Services.
                    </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '10pt', lineHeight: 'normal' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                    Please note that this Agreement contains disclaimers of warranties and limitations on liability that may be applicable to you.
                    </span>
                </p>

                {/* Section 1 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                        1.&nbsp;&nbsp; Intended Users. EASTBADGE Services are intended to be used by users from the United States, Canada, the United Kingdom, and the European Union. While using the Service, your use of the Service must not violate any applicable laws, including copyright or trademark laws, export control or sanctions laws, or other laws in your or our jurisdiction. You are responsible for making sure that your use of the Service is in compliance with laws and any applicable regulations.
                    </span>
                </p>

                {/* Section 2 */}
                <p className="MsoNormal" style={{ lineHeight: 'normal', marginBottom: '0.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                    2.&nbsp;&nbsp; Permitted Uses.&nbsp;&nbsp;
                    </span>
                </p>

                <ol start="1" type="a" style={{ listStylePosition: 'inside', paddingLeft: '1.5em' }}>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;License. Subject to full compliance with this Agreement, EASTBADGE grants you a nonexclusive, nontransferable, non-sublicensable, terminable license to access and use our Services for your use. This license does not include any rights to use, modify, distribute, or reproduce any code, software, or other proprietary content from our website.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;Private Use. The services made available on, by or through our Services, as well as any information provided on, by or through our Services, including but not limited to data, text, graphics, designs, logos, images, audio/visual materials, links, and references (collectively, the “<b>Information</b>”), are provided for your use only. Any use of the Information for any for-profit, commercial, or non-profit activities or purposes, or for resale, is strictly prohibited, except as expressly permitted herein.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;Reproduction. Without the express written consent of EASTBADGE, no Information or any other EASTBADGE materials or property may be copied, reproduced, displayed, republished, downloaded, posted, digitized, translated, modified, transmitted, distributed, or commercially exploited in any way, except as expressly permitted in this Agreement.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;Unauthorized Use. You understand and agree that you may not authorize any Information to be reproduced, modified, displayed, performed, transferred, distributed, or otherwise used by any third party, and you agree that you will take all reasonable steps to prevent any unauthorized reproduction and/or other use of the Information. You agree to advise EASTBADGE promptly of any such unauthorized use of which you are aware. Failure to abide by these conditions will immediately terminate permission to use our Services and may result in the infringement of the copyrights and/or trademarks and other proprietary rights of EASTBADGE or others.
                        </span>
                    </li>
                </ol>

                {/* Section 3 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                        3.&nbsp;&nbsp; User Generated Content ("UGC"). EastBadge may include various types of content, including content you create or submit (“Your Content”). We do not endorse or guarantee the accuracy or reliability of Your Content.
                    </span>
                </p>

                <ol start="1" type="a" style={{ listStylePosition: 'inside', paddingLeft: '1.5em' }}>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;User Responsibilities. By posting User-Generated Content (UGC), you represent and warrant that you either own all rights to the content or have obtained all necessary permissions from the rightful owner(s) to use and grant us the rights to your UGC. You are solely responsible for ensuring that your UGC does not infringe upon any intellectual property rights, privacy rights, or any other legal rights of third parties. Additionally, you must ensure that your UGC complies with all applicable laws and regulations. You agree to indemnify and hold us harmless from any claims, damages, or expenses (including reasonable attorneys’ fees) arising from any breach of these representations and warranties. Furthermore, you agree to pay any royalties, fees, or other amounts owed to any third party due to your UGC.
                        </span>
                    </li>

                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;License to Use Content. You retain ownership of your UGC. However, by submitting UGC, you grant EastBadge a worldwide, royalty-free, perpetual, irrevocable, non-exclusive, transferable, and sublicensable license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform, and display your UGC in any media formats and through any media channels now known or hereafter developed. This license includes the right for EastBadge to make your UGC available to its partners, affiliates, and other third parties for syndication, broadcast, distribution, or publication. You hereby waive any claims of moral rights or rights of attribution with respect to your UGC.
                        </span>
                    </li>

                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;Feedback. Any feedback, suggestions, or ideas you provide to EastBadge regarding our services or products are entirely voluntary. By providing feedback, you grant EastBadge a perpetual, irrevocable, worldwide, royalty-free license to use, reproduce, modify, distribute, and display the feedback for any purpose without any obligation to you. EastBadge is under no obligation to maintain the confidentiality of your feedback or to compensate you for its use.
                        </span>
                    </li>

                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;Content Management. EastBadge reserves the right, at its sole discretion, to review, edit, delete, or remove any UGC for any reason, including but not limited to, violations of our terms of service, privacy policy, or any other applicable policies. We may also remove UGC that we believe is inappropriate, offensive, illegal, or that may harm the rights, property, or safety of EastBadge, its users, or third parties. You acknowledge that EastBadge has no obligation to monitor or enforce your intellectual property rights in your UGC.
                        </span>
                    </li>

                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;Disclosure and Preservation. EastBadge may preserve and disclose your UGC and related information if required to do so by law or in a good faith belief that such preservation or disclosure is reasonably necessary to: (a) comply with legal processes, such as a court order or subpoena; (b) enforce our terms of service; (c) respond to claims that any UGC violates the rights of third parties; (d) address fraud, security, or technical issues; or (e) protect the rights, property, or personal safety of EastBadge, its users, or the public.
                        </span>
                    </li>

                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;Modification and Enforcement. EastBadge reserves the right to modify, adapt, or create derivative works from your UGC for the purpose of distribution, publication, or any other use in accordance with the license granted herein. Additionally, EastBadge may remove or refuse to publish any UGC at its discretion. You authorize EastBadge to act on your behalf to enforce your intellectual property rights against unauthorized use or infringement of your UGC by third parties.
                        </span>
                    </li>

                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;Paid User-Generated Content (UGC). EastBadge may offer monetary compensation to Experts and Users for creating content that can be published and sold on the Site and through other channels. All content, results, proceeds, and intellectual property developed by a User, whether alone or jointly, that are made, conceived, developed, reduced to practice, or learned by the User in the course and scope of their agreement with EastBadge, via email or other channels, are the exclusive property of EastBadge. We reserve the right, at its sole discretion, to obtain and own all copyrights (including renewals and extensions) for this Content. EastBadge retains the right to make this content available, either free or paid, to any and all Users at any time in the future, without the need to inform the creator of its usage. The creator is prohibited from recreating the same content for any other party or making this content available online or offline, free or paid, without explicit written permission from EastBadge.
                        </span>
                    </li>
                </ol>

                {/* Section 4 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                        4.&nbsp;&nbsp; Third Party Links. Our Services may contain links to other Services for your convenience. The fact that we offer such links does not indicate any approval or endorsement by us of any linked Services or any material contained on any linked Services, and we disclaim any such approval or endorsement. We do not control the linked Services, or the content provided through such Services, and we have not reviewed, in their entirety, such Services. Your use of linked Services is subject to the privacy practices and terms of use established by the specific linked Services, and we disclaim all liability for such use.
                    </span>
                </p>

                {/* Section 5 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                        5.&nbsp;&nbsp; User Information Collected. Use of our Services includes the submission of personal information through our website. EASTBADGE collects information that you provide us directly or automatically when you use our Services. This information may include data collected about your name, email address, phone number, targeted institution, targeted position, targeted programs, time zone, review information, or any other information provided by you. All specific information required to use our Services is outlined in our Privacy Policy located at <a href="https://eastbadge.com/privacy-policy" target="_blank" rel="noopener noreferrer">https://eastbadge.com/privacy-policy</a>.
                    </span>
                </p>

                {/* Section 6 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                        6.&nbsp;&nbsp; Expert Information Collected. Use of our Services includes the submission of personal information through our website. EASTBADGE collects information from our Experts when they use our Services. This information may include:
                    </span>
                </p>

                <ol start="1" type="a" style={{ listStylePosition: 'inside', paddingLeft: '1.5em' }}>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;Registration information to include name, email, phone number, LinkedIn profile, Bio, Past Experiences, companies worked, roles, duration of the roles, skills, certifications, programming languages and tools, language proficiency, session notice period, and calendaring information.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;Session availability to include days of the week when expert is available for session, times of day when expert is available for session, days of the week when expert is not available for session, and time zone of the expert.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;Payment information to include bank details or other payment method details to credit the session amount to the expert, mailing address, and any other information required to make a successful payment.
                        </span>
                    </li>
                </ol>

                {/* Section 7 */}
                <p className="MsoNormal" style={{ lineHeight: 'normal', marginBottom: '0.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                        7.&nbsp;&nbsp; IP Ownership.&nbsp;&nbsp;
                    </span>
                </p>

                <ol start="1" type="a" style={{ listStylePosition: 'inside', paddingLeft: '1.5em' }}>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;EASTBADGE Copyright. Our Services are owned and operated by EASTBADGE and its licensors, and the Information (and any intellectual property and other rights relating thereto) is and will remain the property of EASTBADGE. The Information is protected by U.S. and international copyright, trademark, and other laws, and you acknowledge that these rights are valid and enforceable. Except as set forth in this Agreement, you may not copy, reproduce, modify, adapt, translate, republish, upload, post, transmit, distribute, sub-license, sell, reverse engineer, decompile, or disassemble any part of our Services or any Information without our prior written permission. Our Services and Information may be used solely (a) to the extent permitted in this Agreement or (b) as expressly authorized in writing by EASTBADGE. Use of our Services or any Information for any other purpose is strictly prohibited. You acknowledge that you do not acquire any ownership rights by using our Products and Services or any Information.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;EASTBADGE Trademarks. The trademarks and logos displayed on our Services (collectively, the (“<b>Trademarks</b>”)) are the registered and unregistered trademarks of EASTBADGE. Nothing contained in this Agreement, or our Services should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any Trademark(s) without the express written permission of EASTBADGE, except as set forth in this section. You acknowledge and agree that all rights in and to the EASTBADGE trademarks are our exclusive property, and any goodwill generated by your use of any EASTBADGE trademark will inure to our exclusive benefit. You will not take any action that conflicts with our rights in or ownership of any EASTBADGE trademark.
                        </span>
                    </li>
                </ol>

                {/* Section 8 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                        8.&nbsp;&nbsp; DMCA Notices. If you are a copyright owner or an agent thereof and believe that any User Generated Content or other content on the site infringes upon your copyrights, you may submit a notification pursuant to the Digital Millennium Copyright Act (“<b>DMCA</b>”) by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail): A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;
                    </span>
                </p>

                <ol start="1" type="a" style={{ listStylePosition: 'inside', paddingLeft: '1.5em' }}>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site;
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled and information reasonably sufficient to permit the service provider to locate the material;
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;Information reasonably sufficient to permit the service provider to contact you, such as an address, telephone number, and, if available, an electronic mail;
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and a statement that the information in the notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
                        </span>
                    </li>
                </ol>

                <p className="MsoNormal" style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black', marginBottom: '0.5em', paddingLeft: '1.5em' }}>
                    You can send your notice to DMCA <a href="mailto:dmca@eastbadge.com" style={{ color: 'black' }}>dmca@eastbadge.com</a>. A copy of your DMCA Notification will be sent to the person who uploaded the material addressed in the Notification. Please be advised that under Section 512(f) of the Digital Millennium Copyright Act, you may be held liable for damages and attorneys’ fees if you make material misrepresentations in a DMCA Notification.
                </p>

                {/* Section 9 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                        9.&nbsp;&nbsp; No Use by Children under 18. You hereby affirm that you are over the age of 18, as these Services are not intended for children under 18. If you are under 18 years of age, then you may not use our Services. You further affirm that you are fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in this Agreement, and to abide by and comply with this Agreement.
                    </span>
                </p>

                {/* Section 10 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                        10.&nbsp;&nbsp; Term & Termination. This Agreement is effective from the date that you first access our Services and shall remain effective until terminated in accordance with its terms. EASTBADGE may immediately terminate this Agreement, and/or your access to and use of our Services, or any portion thereof, at any time and for any reason, with or without cause, without prior notice. You understand that EASTBADGE may exercise this right in its sole discretion, and this right shall be in addition to and not in substitution for any other rights and remedies available to EASTBADGE. This Agreement will also terminate automatically if you fail to comply with any term or provision of this Agreement. Upon termination of this Agreement by either party, your right to use our Services shall immediately cease, and you shall destroy all copies of information that you have obtained from our Services, whether made under the terms of this Agreement or otherwise. All disclaimers and all limitations of liability and all EASTBADGE rights of ownership shall survive any termination.
                    </span>
                </p>

                {/* Section 11 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                        11.&nbsp;&nbsp; Payments by Users. Users are required to pre-pay for all Services in advance. Payment must be made in full prior to the commencement of any Services. Accepted payment methods include credit card, debit card, and other payment options as specified on our website. By prepaying for Services, you agree to the terms and conditions outlined in this agreement. All payments are non-refundable, except as otherwise provided in this agreement or required by applicable law. We reserve the right to modify our payment terms at any time, and such changes will be effective immediately upon posting on our website or other communication channels.
                    </span>
                </p>

                {/* Section 12 */}
                <p className="MsoNormal" style={{ lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em', marginBottom: '0.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                        12.&nbsp;&nbsp; Refunds to Users. Requests for refunds on prepaid Services will be evaluated individually.
                    </span>
                </p>

                <ol start="1" type="a" style={{ listStylePosition: 'inside', paddingLeft: '1.5em' }}>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;Requests for Refunds. Users can request refunds for prepaid Services by contacting our customer service team at <a href="mailto:support@eastbadge.com" style={{ color: 'black' }}>support@eastbadge.com</a> or submitting a request through their account page within 24 hours of the session completion. Refund requests will be evaluated individually and issued at our discretion, based on the nature of the request and the circumstances surrounding it. Payments to the expert providing the Services will not be made until the refund request period has lapsed. If a refund is approved, it will be processed using the original payment method within 30 days of approval. This policy does not affect your statutory rights.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;Missed Sessions. Users can request a refund if a coach misses a session. EastBadge will require up to 24 hours to validate this claim. Once validated, a refund will be issued immediately. Refunds are typically processed within 24 hours by EastBadge but may take up to 5-10 working days depending on the User’s bank and its processing times.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;Fraudulent Transactions. EastBadge, through its Payment Services providers, reserves the right to cancel transactions or place funds on hold for any suspected fraudulent transactions made on the Site.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;Chargebacks. Initiating a chargeback via a payment provider without first requesting a refund through EastBadge is a violation of EastBadge’s Terms of Service. Such actions may result in the user’s account being temporarily or permanently disabled. EastBadge retains the right to decline a refund, refuse a future transaction, or adjust the pricing of a future transaction based on the outcome of the dispute resolution process. EastBadge also reserves the right to employ a payment collection service to recoup funds for chargebacks made without explanation and without response.
                        </span>
                    </li>
                </ol>

                {/* Section 13 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                        13.&nbsp;&nbsp; Payments to Experts. All payments for services will be made within 30 days of the engagement date. Generally, payment will be initiated within 24 hours after the expert completes the session and submits the session feedback. However, if users are not satisfied with the services provided by our experts, they may be entitled to a full refund, and the expert will not be paid for that session. If a chargeback is received for any expert session, the expert will be charged for the amount of the chargeback. If an expert arrives late, misses a session, or if the session is not held for any reason attributable to the expert, the user will be entitled to a full refund or a rescheduled session at no additional cost. To protect both the users, experts, and the company, any disputes regarding session attendance, punctuality, or service quality will be reviewed on a case-by-case basis. The company reserves the right to make the final decision on refunds, chargebacks, and penalties.
                    </span>
                </p>

                {/* Section 14 */}
                <p className="MsoNormal" style={{ lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em', marginBottom: '0.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                        14.&nbsp;&nbsp;&nbsp; Additional User Terms.&nbsp;&nbsp;
                    </span>
                </p>

                <ol start="1" type="a" style={{ listStylePosition: 'inside', paddingLeft: '1.5em' }}>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;Scheduling and Payments. Users must not schedule session(s) with experts outside of EastBadge. Users must not offer direct payments to Experts. EastBadge makes the payouts to the experts once a session is completed. By making a payment to EastBadge or providing payment information, a user confirms that they are legally authorized to provide such information and have permission to make payments using the payment method.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;Session Management. Should the user choose to cancel the session or ask for a refund, any payment processing fees will not be refunded because that is charged by the third-party payment processors we rely on. After the session starts, users cannot reschedule or cancel unless the expert fails to show, in which case a full refund or free reschedule will be provided. If a user misses a session, EastBadge is not required to provide a free reschedule or a refund. EastBadge might still choose to provide a refund at its own discretion.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;Prohibited Actions. Users should not ask any sensitive information from the Expert concerning the company, organization, school, or other institution. The Expert is not required to provide any such information to the users. As a user, you will not under any circumstances, use the service/platform/application to ask experts for their personal information. As a user, you will not under any circumstances, use the service/platform/application for illegal, harassing, unethical, or disruptive purposes. If this behavior is reported, EastBadge reserves the right to cancel the session with no refund at its sole discretion. EastBadge also reserves the right to cancel/suspend the User’s account at its sole discretion.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;Disputes and Refunds. Filing a transaction dispute or reversing a payment through the user’s payment provider or the user’s bank is a violation of these Terms of Use. Doing so may get the user’s account temporarily or permanently disabled. Note: once the user has filed a dispute with the user’s payment provider, the funds will be ineligible for a refund due to our obligations towards the payment provider. EastBadge reserves the right to cancel transactions or place funds on hold for suspected fraudulent transactions made on the application.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;Conduct and Protection. EastBadge fosters a friendly, community-spirited, and professional environment. Users should uphold this spirit in all activities. To report violations of our Terms of Service, User Misconduct, or for account inquiries, contact <a href="mailto:support@eastbadge.com" style={{ color: 'black' }}>support@eastbadge.com</a>. User identities are kept anonymous to protect privacy. Sharing personal contact details to communicate outside EastBadge is prohibited. Necessary personal information exchanges should occur via EastBadge customer support or approved channels. Users must attend sessions on time and use quiet zones to avoid disruptions. EastBadge is not liable for interactions outside its platform. All information exchanges must occur on EastBadge’s platform. Rude, abusive, or discriminatory behavior is not tolerated and may result in account suspension or removal.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;Feedback. A user can access the expert’s written feedback after they have provided their own feedback on the expert. Please note that receiving written feedback upfront is not guaranteed.
                        </span>
                    </li>
                </ol>

                {/* Section 15 */}
                <p className="MsoNormal" style={{ lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em', marginBottom: '0.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                        15.&nbsp;&nbsp;&nbsp; Additional Expert Terms.&nbsp;&nbsp;
                    </span>
                </p>

                <ol start="1" type="a" style={{ listStylePosition: 'inside', paddingLeft: '1.5em' }}>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;Scheduling and Payments. Experts should not schedule sessions with users outside of EastBadge. Experts should not ask for direct payments from users at any point. EastBadge makes the payout to the experts. An expert is entitled to payout for a session only after they have completed the session and submitted the feedback form after the completion of the session. No payout will be made until this is completed. By providing a payout preference to EastBadge via the application, through email, or any other medium, the expert represents and warrants that: (a) the expert is legally authorized to provide such information; (b) the expert is legally authorized or has permission to receive payments using the payment method(s); (c) if the expert is an employee or agent of a company or person that owns the payment method, the expert is authorized by that company or person to use the payment method to make payments to EastBadge and receive payouts from EastBadge; (d) such actions do not violate any applicable law; and (e) the expert will take all actions necessary in order to stay compliant with the laws of any jurisdictions which apply to them.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;Session Management. Experts must provide their availability to EastBadge in terms of days of the week and time of day that works for them. They can separately specify the dates that do not work for them. EastBadge will not show the experts as available for sessions on the platform unless they provide their availability. EastBadge matches the expert with relevant users, and the expert agrees to take session(s) with the user if they are available. Experts must not deny service based on preliminary information provided about the user to the expert.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;Prohibited Actions. As an expert, you will not under any circumstances, use the service/platform/application to ask users for their personal information. As an expert, you will not under any circumstances, use the service/platform/application for illegal, harassing, unethical, or disruptive purposes.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;Conflicts of Interest and Sensitive Information. Experts must promptly declare any conflicts of interest to ensure they do not negatively impact a user’s application. If an expert suspects a potential conflict of interest, they should decline or cancel the session to avoid any issues. Experts are prohibited from sharing any information labeled as “sensitive” or “confidential” by their employer, school, organization, or any other institution. They must adhere to all relevant policies and contractual obligations, including non-disclosure agreements (NDAs). If an expert is asked to interview a user at their current employer, school, organization, or any other institution, and they have previously conducted a session with this user on EastBadge, they must declare the conflict of interest and remove themselves from the hiring, admission, or other relevant processes. Similarly, if an expert is asked to conduct a session on EastBadge with a user they are potentially interviewing or interacting with at their employer, school, organization, or any other institution, they must declare the conflict of interest to EastBadge. This allows EastBadge to recommend an alternative expert or cancel the session request and issue a refund. EastBadge advises adopting a cautious stance regarding Conflicts of Interest and Sensitive Information. If an expert believes they may be breaching any contractual or legal obligations, they should refrain from providing services to the user and promptly inform EastBadge to facilitate a refund.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;Schedule Modification Policy. An expert can request a reschedule, cancellation, or modification for a session. However, a user has the right to decline a reschedule and instead request a refund, in which case the expert is not entitled to any payout for that session. If an expert is late to the session, they must compensate for the lost time and provide the user with a session for the entire duration initially promised. The user also has the right to request cancellation of the session and receive a full refund, and in this scenario, the expert is not entitled to any payout. Additionally, if an expert fails to provide the user with a session for the entire promised duration (except when the user was late), the user can request a refund, and EastBadge has the right to cancel the payout for that session. EastBadge may choose to issue a partial payout for such sessions at its sole discretion.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Century", serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Century", serif' }}>
                            &nbsp;&nbsp;Taxes and Independent Contractor Status. As an expert providing services through our platform, you acknowledge and agree that you are an independent contractor. Nothing in these Terms of Use shall be construed to create a partnership, joint venture, agency, or employer-employee relationship between you and our company. As an independent contractor, you are responsible for the payment of all applicable taxes, including but not limited to income tax, self-employment tax, and any other taxes required by federal, state, or local law. You agree to indemnify and hold our company harmless from any claims, liabilities, or penalties arising from your failure to pay such taxes. You agree to comply with all applicable laws and regulations in the performance of your services, including but not limited to those related to tax obligations, labor, and employment. As an independent contractor, you are not entitled to any benefits that may be provided to employees of our company, including but not limited to health insurance, retirement plans, or paid time off. An expert’s rating, number of sessions, and payout may influence the number of potential session requests they receive on EastBadge. Payouts can depend on the expert’s past experience, including their roles, affiliations, and educational background. EastBadge does not guarantee a fixed number of sessions for any expert during a given period, and being on the platform does not ensure any session requests.
                        </span>
                    </li>
                </ol>

                {/* Section 16 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                        16.&nbsp;&nbsp; Disclaimers. OUR SERVICES ARE PROVIDED “AS IS” AND “WITH ALL FAULTS” AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, AND ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, ACCURACY, COMPLETENESS, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ANY WARRANTIES THAT MAY ARISE FROM COURSE OF DEALING, COURSE OF PERFORMANCE OR USAGE OF TRADE, AND ANY WARRANTIES THAT THE INFORMATION AND SERVICES ARE CURRENT AND/OR UP-TO-DATE ARE HEREBY EXPRESSLY DISCLAIMED TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW.
                    </span>
                </p>
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '0em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                        THERE IS NO WARRANTY, REPRESENTATION OR GUARANTEE THAT OUR SERVICES, OR YOUR USE OF OUR SERVICES, WILL BE UNINTERRUPTED, COMPLETE, ACCURATE, CURRENT, RELIABLE, ERROR-FREE, SECURE, OR THAT ANY PROBLEMS WILL BE CORRECTED, OR THAT OUR SERVICES, OR ANY INFORMATION OR OTHER MATERIAL ACCESSIBLE FROM OUR SERVICES, IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. WE DO NOT WARRANT, GUARANTEE, OR MAKE ANY REPRESENTATION REGARDING THE USE OF, OR THE RESULTS OF THE USE OF OUR SERVICES, AND YOU ASSUME ALL RESPONSIBILITY AND RISK FOR YOUR USE OF OUR SERVICES AND INFORMATION AND YOUR RELIANCE THEREON.
                    </span>
                </p>

                {/* Section 17 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                        17.&nbsp;&nbsp; Limitation of Liability. NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY OF ANY KIND, NEITHER EASTBADGE NOR ANY OF ITS AGENTS, SUCCESSORS, OR ASSIGNS, NOR EASTBADGE MEMBERS, OFFICERS, EMPLOYEES, CONSULTANTS, OR OTHER REPRESENTATIVES, ARE RESPONSIBLE OR LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE, OR OTHER DAMAGES (INCLUDING WITHOUT LIMITATION ANY LOSS OF PROFITS, LOST SAVINGS, OR LOSS OF DATA) OR LIABILITIES UNDER ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY, OR OTHER THEORY ARISING OUT OF OR RELATING IN ANY MANNER TO OUR SERVICES, INFORMATION, AND/OR ANY LINKED PRODUCTS AND SERVICES, WHETHER OR NOT WE HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGES OR LIABILITIES. YOUR SOLE REMEDY WITH RESPECT TO ANY CLAIMS RELATED TO THESE SERVICES, THE INFORMATION, OR ANY LINKED PRODUCTS AND SERVICES IS TO STOP USING OUR SERVICES, OR LINKED SERVICES, AS APPLICABLE AND A FULL REFUND OF THE FEES PAID FOR THE SERVICES.
                    </span>
                </p>
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '0em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                        NEITHER EASTBADGE NOR ANY OF ITS AGENTS, SUCCESSORS, OR ASSIGNS, NOR EASTBADGE’S MEMBERS, OFFICERS, EMPLOYEES, CONSULTANTS, OR OTHER REPRESENTATIVES WILL HAVE ANY LIABILITY TO YOU FOR ANY DAMAGES, EXPENSES OR OTHER LIABILITY INCURRED BY YOU AS A RESULT OF ANY INACCURACY, INCOMPLETENESS OR MISREPRESENTATION OF ANY INFORMATION, CONTENT, POSTINGS.
                    </span>
                </p>

                {/* Section 18 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                        18.&nbsp;&nbsp; Indemnification. You agree to fully indemnify, defend, and hold EASTBADGE its agents, successors, and assigns and EASTBADGE’s directors, officers, employees, consultants, and other representatives, harmless from and against any and all claims, damages, losses, costs (including reasonable attorneys' fees), and other expenses that arise directly or indirectly out of or from: (a) your breach of this Agreement; (b) any allegation that any materials you submit to us or transmit to our Services infringe or otherwise violate the copyright, patent, trademark, trade secret, or other intellectual property or other rights of any third party; (c) your activities in connection with our Services or other Services to which our Services are linked; and/or (d) your negligence or willful misconduct.
                    </span>
                </p>

                {/* Section 19 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                        19.&nbsp;&nbsp; Other Jurisdictions. EASTBADGE makes no representation that our Services operate (or are legally permitted to operate) in all geographic areas, or that our Services or Information are appropriate or available for use in other locations. Accessing our Services from territories where our Services or any content or functionality of our Services or portion thereof is illegal is expressly prohibited. If you choose to access our Services, you agree and acknowledge that you do so on your own initiative and at your own risk, and that you are solely responsible for compliance with all applicable laws.
                    </span>
                </p>

                {/* Section 20 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                        20.&nbsp;&nbsp; Notice. By use of our Services, you consent to receive electronic communications from EASTBADGE. You also agree that any such communications satisfy any legal requirement to make such communications in writing under this Agreement or under any applicable laws or regulations. Specifically, we may provide notice to you by sending an email to the address that you provided as part of your registration for our Products and Services. Any notice to EASTBADGE will be provided by both (a) send via an email to <a href="mailto:legal@eastbadge.com" style={{ color: 'black' }}>legal@eastbadge.com</a>; and (b) providing a copy of the notice by certified mail, return receipt requested to: EASTBADGE, INC., 166 Geary Str STE 1500 #1458, San Francisco, CA 94108, ATTN: Legal.
                    </span>
                </p>

                {/* Section 21 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                        21.&nbsp;&nbsp; No Waiver. No delay or omission by either party in exercising any rights under this Agreement will operate as a waiver of that or any other right. A waiver or consent given by either party on any one occasion is effective only in that instance and will not be construed as a bar to or waiver of any right on any other occasion.
                    </span>
                </p>

                {/* Section 22 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                        22.&nbsp;&nbsp; Assignment. Neither this Agreement nor any right, obligation, or remedy hereunder is assignable, transferable, delegable, or sublicensable by you except with EASTBADGE’s prior written consent, and any attempted assignment, transfer, delegation, or sublicense shall be null and void. EASTBADGE may assign, transfer, or delegate this Agreement or any right or obligation or remedy hereunder in its sole discretion.
                    </span>
                </p>

                {/* Section 23 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                        23.&nbsp;&nbsp; Right to Change Terms. We reserve the right at any time, with or without cause, to (a) change the terms and conditions of this Agreement; (b) change our Services; or (c) deny or terminate your use of and/or access to our Services. Any changes we make will be effective immediately upon our making such changes to our Services, with or without additional notice to you. You agree that your continued use of our Services after such changes constitutes your acceptance of such changes. You hereby acknowledge that you have carefully read all the terms and conditions of our Privacy Policy (which can be accessed at <a href="https://eastbadge.com/privacy-policy" target="_blank" rel="noopener noreferrer">https://eastbadge.com/privacy-policy</a>) and agree to all such terms and conditions. Be sure to return to this page periodically to ensure familiarity with the most current version of this Agreement. YOUR CONTINUED USE OF OUR SERVICES AFTER SUCH POSTING MEANS YOU ACCEPT AND AGREE TO BE BOUND BY THE MODIFIED TERMS OF USE.
                    </span>
                </p>

                {/* Section 24 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                        24.&nbsp;&nbsp; Mediation and Arbitration. Any controversy between the Parties to this Agreement involving the construction or application of any of the terms, provisions, or conditions of this Agreement, shall on written request of either party served on the other, be submitted first to mediation and then if still unresolved to binding arbitration. Said mediation or binding arbitration shall comply with and be governed by the provisions of the American Arbitration Association for Commercial Disputes unless the Parties stipulate otherwise. The attorneys’ fees and costs of arbitration shall be borne by the losing party unless the Parties stipulate otherwise, or in such proportions, as the arbitrator shall decide.
                    </span>
                </p>

                {/* Section 25 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                        25.&nbsp;&nbsp; Governing Law & Venue. This Agreement shall be governed in all respects by the laws of the United States of America and by the laws of the State of Texas. The Courts of Harris County, Texas shall be the exclusive forum for any mediation, arbitration, litigation, or dispute resolution.
                    </span>
                </p>

                {/* Section 26 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                        26.&nbsp;&nbsp; Class Action Waiver: To the maximum extent permitted by applicable law, You and EASTBADGE agree to only bring Disputes in an individual capacity and shall not 1. seek to bring, join, or participate in any class or representative action, collective or class-wide arbitration, or any other action where another individual or entity acts in a representative capacity (e.g., private attorney general actions); or 2. consolidate or combine individual proceedings or permit an arbitrator to do so without the express consent of all parties to this Agreement and all other actions or arbitrations.
                    </span>
                </p>

                {/* Section 27 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                        27.&nbsp;&nbsp; Enforceability. If any provision of this Agreement is found to be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from this Agreement and shall not affect the validity and enforceability of any remaining provisions.
                    </span>
                </p>

                {/* Section 28 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Century", serif', color: 'black' }}>
                        28.&nbsp;&nbsp; Entire Agreement. This Agreement, along with the Privacy Policy, constitute the entire agreement with respect to the relationship between EASTBADGE and you and supersedes all prior agreements, whether written or oral, concerning such relationship. This Agreement may not be changed, waived, or modified except by EASTBADGE as provided herein or otherwise by written instrument signed by EASTBADGE.
                    </span>
                </p>

            </div>
            </Container>
    );
};

export default TermsOfUse;
